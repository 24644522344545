import { render, staticRenderFns } from "./addSignSuccess.vue?vue&type=template&id=5f29969f&scoped=true&"
import script from "./addSignSuccess.vue?vue&type=script&lang=js&"
export * from "./addSignSuccess.vue?vue&type=script&lang=js&"
import style0 from "./addSignSuccess.vue?vue&type=style&index=0&id=5f29969f&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f29969f",
  null
  
)

export default component.exports